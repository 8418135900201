<template>
    <div class="row">
        <div class="col-12 align-self-end">
            <div class="row">
                <div class="col-12 py-2">
                    <hr/>
                </div>
                <div class="col-12 col-lg-11 mx-auto align-self-center py-2">
                    <div class="row">
                        <div class="col-12 col-lg-6 col-xl-3">
                            <p> Powered by 
                                <img src="/assets/imgs/vysio_logo.png" class="img-fluid footer-logo" alt="vysio-srl"/>
                            </p>                     
                            <p class="py-2">
                                <b>Vysio S.r.l.</b> - 
                                <b>P.IVA</b>: IT05156040759 <br/>
                                Via Carlo Magno 17 73042 Casarano (Le) Italia <br/>
                            </p>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-3">
                            <p>
                                <span class="text-highlight">{{ $t('footer.contacts') }}</span><br/>
                                <i class="fa-solid fa-phone"></i> &nbsp;+39 338 82 83 304 <br/>
                                <i class="fa-solid fa-envelope"></i> &nbsp;info@adaptaeasy.it <br/>
                                <i class="fa-solid fa-location-dot"></i> &nbsp;
                                Viale Cav. A. Filograna 1340 Z.I. 73042 Casarano (Le) Italia
                                <!-- <i class="fa-solid fa-globe"></i>&nbsp;www.adaptaeasy.it <br /> -->
                            </p>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-3">
                            <p>
                                <span class="text-highlight">{{ $t('footer.social') }}</span><br/>
                                <i class="fa-brands fa-facebook-f"></i>&nbsp;<a href="https://www.facebook.com/adaptaeasy/" target="_blank">Facebook</a> <br/>
                                <i class="fa-brands fa-instagram"></i>&nbsp;<a href="https://instagram.com/adapta_easy" target="_blank">Instagram</a> <br/>
                                <i class="fa-brands fa-youtube"></i>&nbsp;<a href="https://www.youtube.com/channel/UC3qqhEz17ZZlb6sCKY-Y0UA/about" target="_blank">YouTube</a> <br/>
                            </p>
                        </div>
                        <div class="col-12 col-lg-12 col-xl-12 bg-white">
                            <!-- <p>
                                <span class="text-highlight">{{ $t('footer.gdpr') }}</span> <br/>
                                <router-link to="/privacy-policy" target="_blank">Privacy Policy</router-link><br/>      
                                <router-link to="/cookie-policy" target="_blank">Cookie Policy</router-link><br/>
                            </p> -->
                            <div class="flex flex-col py-4">
                                <div class="text-left mb-3">
                                    <p class="text-black" style="font-size: 0.9rem;">
                                        Iniziativa cofinanziata dall’Unione Europea, mediante il PO Puglia FESR FSE 2014 - 2020 <br/>
                                        Asse I - Ricerca, Sviluppo Tecnologico e Innovazione Azione 1.5 - Asse III - Competitività delle piccole e medie imprese Azione 3.8 - Misura Tecnonidi 
                                    </p>
                                </div>
                                <div class="flex flex-wrap">
                                    <img src="/assets/imgs/loghi-rend-ue.png" style="max-height: 6rem;" class="img-fluid" alt="Unione Europea"/>
                                    <img src="/assets/imgs/loghi-rend-mise.png" style="max-height: 6rem;" class="img-fluid" alt="Ministero dello Sviluppo Economico"/>
                                    <img src="/assets/imgs/loghi-rend-reg.png" style="max-height: 6rem;" class="img-fluid" alt="Regione Puglia"/>
                                    <img src="/assets/imgs/loghi-rend-fesr.png" style="max-height: 6rem;" class="img-fluid" alt="POR FESR-FSE"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr/>
                </div>
                <div class="col-12 mx-auto">
                    <p class="text-center copytext">Copyright &copy; Vysio s.r.l. 2023  - Crafted and Designed by <a href="www.jacopogrecuccio.it" class="copytext">JackGreek</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
};
</script>
