export default {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
    "idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idea"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifications"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "landingBanner": {
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the tutor is at your disposal for the correct use of the lenses."])},
    "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the patented Adapta Easy system, we help the optometrist to overcome the obstacles related to all those lenses that need adaptation to use, progressive lenses, prismatic lenses, and lenses with high cylinder entities. The goal is to ensure and manage adaptation in a more gentle and faster way."])},
    "watchVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch the video"])},
    "discoverMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover More"])}
  },
  "idea": {
    "concept": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where did the idea come from?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How a person «sees» is the result of years of development. We all form motor and sensory patterns that give us efficiency and effectiveness.Everything we've learned is called into question when we put a lens in front of our eyes. All this is amplified when we put a progressive lens in front of the eyes, as it intrinsically alters different perceptual aspects:Peripheral perception - Saccades - Vergences - Focus - Distance estimation - Size estimation - Motion programming.In Italy, the latest SOI (Società Oftalmologica Italiana) estimates 28 million presbyters, about 50% of the population.On the other hand, despite being one of the oldest countries in the world with Japan, penetration of Progressive Lenses sale is - according to GFK data - below by 20%.Why do 8 Italians (presbyters) out of 10 not choose the progressive lens?Because there are prejudices against the progressive lens due, in almost all cases, to the lack of adaptation. The Adapta Easy system was created to eliminate these prejudices and make adaptation affordable for everyone."])}
    },
    "howItWorks": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does Adapta Easy work?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to Adapta Easy, we have digitalized some standardized procedures which allow us to manage in a few minutes the adaptation in the most accessible and fastest way, guaranteeing the adaptation at 100%. A kit containing all the instruments needed for the performance of proceedings on an interactive table, two digital touch screens, an intelligent balance board connected to the system, and a smart bracelet by which the client should interact to conduct the procedures required by the Protocol. The highest innovation and interactivity guarantee the client's unique customer experience. At the same time, we have solved the most critical issue of the failure of progressive lens adaptation."])}
    }
  },
  "design": {
    "customize": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A unique and customizable design"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The element Desk \"Bridge\" comprises two overlapping modules, of which the L\" upper slides over the lower polyhedral element through a double guide placed in the sub-top between the upper and lower module. Retractable drawer with sliding rails containing the tool kit to perform the training procedures. <br/> <br/>  Possibly upon availability and surcharge to the customer, customize in a series of predefined colors during the pre-order phase."])}
    },
    "easyInstall": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy installation in any environment"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The instrument presented a design suitable for any layout of an optics store, simple and guided installation, power supply with standard 220V socket, in-store assistance during installation via an agent, and remote with vysio help service"])}
    },
    "customizations": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard customizations"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon request, any customization of materials and colors."])},
      "colors": {
        "coal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anthracite"])},
        "gray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light Gray"])},
        "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])},
        "cashmere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashmere"])},
        "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deep Blue"])},
        "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])}
      }
    }
  },
  "specifications": {
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical specifications and characteristics"])},
    "desk": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desk"])},
      "materials_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure with retractable drawer with kit training"])},
      "dimensions_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L. 90+30 x P. 50 x H. 68-76 cm"])},
      "weight_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48 kg"])},
      "monitor_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.6\" reclinable touch monitor (FullHD) "])},
      "supply_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["220V AC"])},
      "network_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi"])}
    },
    "totem": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totem"])},
      "materials_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure in plexiglass transparent methacrylate"])},
      "dimensions_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L. 72 x H. 200 x P. 7cm - (Support base 58x50 cm)"])},
      "weight_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["68 kg"])},
      "monitor_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31.5\" touch monitor (FullHD) "])},
      "supply_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["220V AC"])},
      "network_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi"])}
    },
    "storeManager": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adapta Easy Store-Manager"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It allows the operator to access some valuable tools for the optical assistance of their customers."])}
    },
    "detailVoices": {
      "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials"])},
      "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
      "monitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitor"])},
      "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Supply"])},
      "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity"])}
    },
    "downloadCatalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download our catalogue"])}
  },
  "team": {
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
    "ceo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysio s.r.l. CEO"])},
    "business_developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Developer"])},
    "product_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Manager"])}
  },
  "contactForm": {
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to discover more about Adapta Easy?"])},
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact our team!"])},
    "labels": {
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])}
    },
    "placeholders": {
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your E-Mail address"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your last name"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company's name"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a message"])}
    },
    "sendClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send your request"])},
    "messages": {
      "success": {
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
        "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team will get in touch with you very soon"])}
      },
      "error": {
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops! Something went wrong"])},
        "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected problem has occurred. Please try again."])},
        "retryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])}
      }
    }
  },
  "formErrors": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value for this field"])}
  },
  "footer": {
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
    "gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])}
  }
}