<template>
    <section class="fullpage" style="min-height: 0 !important;">
        <div class="row h-100">
            <div class="col-12 col-xl-4 col-xl-offset-1 col-xxl-3 offset-xxl-2 align-self-center">
                <h1>
                    {{ $t('contactForm.cta') }}
                </h1>
            </div>
            <div 
                class="col-12 col-xl-7 col-xxl-5 mx-auto align-self-center py-5 px-5" 
                v-if="contact_status==='COLLECTING_INFO'"
            >
                <h3>{{ $t('contactForm.claim') }}</h3>
                <form class="w-100" ref="contactForm" @submit.prevent="sendContactEmail()">
                    <div class="mb-3">
                        <label 
                            for="input-f-email" 
                            class="form-label contact-form"
                        >
                            {{ $t('contactForm.labels.mail') }}:
                        </label>
                        <input 
                            type="email" 
                            class="form-control contact-form" 
                            id="input-f-email" 
                            :placeholder="$t('contactForm.placeholders.mail')"
                            v-model="contactInfo.email"
                            name="email"
                        >
                        <div class="input-errors" v-if="v$.contactInfo.email.$error">
                            <div class="error-msg">{{ $t('formErrors.invalid') }}</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="input-f-email" class="form-label contact-form">
                            {{ $t('contactForm.labels.name') }}:
                        </label>
                        <input 
                            type="text" 
                            class="form-control contact-form" 
                            id="input-f-name" 
                            :placeholder="$t('contactForm.placeholders.name')"
                            v-model="contactInfo.name"
                            name="name"
                        >
                        <div class="input-errors" v-if="v$.contactInfo.name.$error">
                            <div class="error-msg">{{ $t('formErrors.required') }}</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="input-f-email" class="form-label contact-form">
                            {{ $t('contactForm.labels.lastname') }}:
                        </label>
                        <input 
                            type="text" 
                            class="form-control contact-form" 
                            id="input-f-lastname" 
                            :placeholder="$t('contactForm.placeholders.lastname')"
                            v-model="contactInfo.lastname"
                            name="lastname"
                        >
                        <div class="input-errors" v-if="v$.contactInfo.lastname.$error">
                            <div class="error-msg">{{ $t('formErrors.required') }}</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="input-f-company" class="form-label contact-form">
                            {{ $t('contactForm.labels.company') }}:
                        </label>
                        <input 
                            type="text" 
                            class="form-control contact-form" 
                            id="input-f-company" 
                            :placeholder="$t('contactForm.placeholders.company')"
                            v-model="contactInfo.company"
                            name="company"
                        >
                        <div class="input-errors" v-if="v$.contactInfo.company.$error">
                            <div class="error-msg">{{ $t('formErrors.required') }}</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="input-f-message" class="form-label contact-form">
                            {{ $t('contactForm.labels.message') }}:
                        </label>
                        <textarea 
                            class="form-control contact-form" 
                            id="input-f-message" 
                            rows="10"
                            v-model="contactInfo.message"
                            :placeholder="$t('contactForm.placeholders.message')"
                            name="message"    
                        ></textarea>
                        <div class="input-errors" v-if="v$.contactInfo.message.$error">
                            <div class="error-msg">{{ $t('formErrors.required') }}</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input contact-form" id="accept-privacy" value="" v-model="privacyCheck"/>
                            <label class="form-check-label contact-form" for="accept-privacy" >
                                Ho letto l'<router-link to="/privacy-policy" target="_blank">informativa sulla privacy</router-link> e acconsento 
                                alla memorizzazione dei miei dati, secondo quanto stabilito dal regolamento europeo per la 
                                protezione dei dati personali n. 679/2016 (GDPR), per avere informazioni sui servizi di www.adaptaeasy.it
                            </label>
                        </div>
                    </div>
                    <div class="mb-3">
                        <button :disabled="privacyCheck===false" class="btn btn-light" type="submit">
                            {{ $t('contactForm.sendClaim') }} &nbsp; <i class="fa-solid fa-arrow-right"></i></button>
                    </div>
                </form>
            </div>
            <div class="col-12 col-xl-7 col-xxl-5 mx-auto align-self-center py-5 px-5 text-center" v-else-if="contact_status==='SENDING'">
                <div class="lds-ripple"><div></div><div></div></div>
            </div>
            <div 
                class="col-12 col-xl-7 col-xxl-5 mx-auto align-self-center py-5 px-5"
                style="min-height: 40vh;" 
                v-else-if="contact_status==='SENT_SUCCESS'"
            >
                <h2>{{ $t('contactForm.messages.success.claim') }}</h2>
                <p>{{ $t('contactForm.messages.success.brief') }}</p>
            </div>
            <div class="col-12 col-xl-7 col-xxl-5 mx-auto align-self-center py-5 px-5" v-else-if="contact_status==='SENT_FAIL'">
                <h2>{{ $t('contactForm.messages.error.claim') }}</h2>
                <p>{{ $t('contactForm.messages.error.brief') }}</p>
                <button 
                    class="btn btn-light" 
                    @click="retry()"
                >
                {{ $t('contactForm.messages.error.retryText') }} &nbsp; <i class="fa-solid fa-arrow-rotate-right"></i>
                </button>
            </div>

        </div>
    </section>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import emailjs from 'emailjs-com';

export default {
    name: 'ContactForm',
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            contact_status: 'COLLECTING_INFO',
            privacyCheck: false,
            contactInfo: {
                lastname: '',
                name: '',
                email: '',
                company: '',
                message: '',
            },
        };
    },
    validations() {
        return {
            contactInfo: {
                lastname: { required },
                name: { required },
                email: { required, email },
                company: { required },
                message: { required },
            },
        };
    },
    methods: {
        sendContactEmail() {
            this.v$.$touch();

            if (this.v$.contactInfo.$invalid) {
                return;
            }

            this.contact_status = 'SENDING';
            emailjs.sendForm(
                process.env.VUE_APP_EMAIL_JS_SERVICE,
                process.env.VUE_APP_EMAIL_JS_TEMPLATE,
                this.$refs.contactForm, 
                process.env.VUE_APP_EMAIL_JS_KEY,
                {
                    email: this.contactInfo.email,
                    name: this.contactInfo.name,
                    lastname: this.contactInfo.lastname,
                    company: this.contactInfo.company,
                },
            )
            .then(
                () => {
                    // console.log('SUCCESS', result.text);
                    this.contact_status = 'SENT_SUCCESS';
                }, 
                () => {
                    // console.log('FAILED...', error.text);
                    this.contact_status = 'SENT_FAIL';
                },
            );
        },
        retry() {
            this.privacyCheck = false;
            this.contact_status = 'COLLECTING_INFO';
        },
    },
};
</script>

<style scoped>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

</style>
