<template>
        <nav class="navbar fixed-top navbar-expand-lg navbar-light navbar-black">
            <div class="container-fluid">
                <a href="#" class="navbar-brand">
                    <img class="nav-logo" src="/assets/imgs/compressed/ae-logo-w-horizontal.webp" alt="adaptaeasy-small-logo"/>
                </a>
                    
                <!-- <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span class="navbar-toggler-icon"></span>
                </button> -->
                <div id="toggler"  class="custom-toggler" @click="expandNav">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
                <div class="collapse navbar-collapse" id="collapsable">
                    <div class="navbar-nav mr-auto">
                        <slot>
                        </slot>
                        <LanguageSelector></LanguageSelector>
                    </div>
                </div>
            </div>
        </nav>
</template>

<script>
import LanguageSelector from './LanguageSelector.vue';

export default {
    name: "Navbar",
    methods: {
        expandNav() {
            document.getElementById("collapsable").classList.toggle("collapse");
            document.getElementById("toggler").classList.toggle("change");
        },
    },
    components: { LanguageSelector },
};
</script>

<style>

</style>
