<template>
    <section class="fullpage">
        <div class="row h-100">
            <div class="col-3 col-lg-3 col-xl-1 offset-xl-0 col-xxl-1 offset-xxl-1 align-self-center">
                <div class="col-12 py-2">
                    <img data-aos="zoom-in-up" data-aos-duration="1500" src="/assets/imgs/compressed/ae-product-2.webp" alt="ae-marketing-3" class="img-fluid"/>
                </div>
                <div class="col-12 py-2">
                    <img data-aos="zoom-in-up" data-aos-duration="1500" src="/assets/imgs/compressed/ae-product-1.webp" alt="ae-marketing-3" class="img-fluid"/>
                </div>
                <div class="col-12 py-2">
                    <img data-aos="zoom-in-up" data-aos-duration="1500" src="/assets/imgs/compressed/ae-product-3.webp" alt="ae-marketing-3" class="img-fluid"/>
                </div>
            </div>
            <div class="col-9 col-lg-9 col-xl-5 col-xxl-4 align-self-center">
                <img data-aos="zoom-in-up" data-aos-duration="1500" src="/assets/imgs/compressed/ae-product-4.webp" alt="ae-marketing-3" class="img-fluid"/>
            </div>
            <div class="col-12 col-lg-12 col-xl-6 col-xxl-5 align-self-center" >
                <div class="row" data-aos-duration="2000" data-aos="flip-right">
                    <div class="col-sm-12  col-lg-10 mx-auto py-3">
                        <h1>{{ $t('specifications.claim') }}</h1>
                    </div>
                    <div class="col-sm-12 col-lg-10 mx-auto">
                        <h5 class="py-2"> {{ $t('specifications.desk.claim') }}</h5>
                        <p class="px-1">
                            <span class="text-highlight">{{ $t('specifications.detailVoices.materials') }}</span>: {{ $t('specifications.desk.materials_data') }}<br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.dimensions') }}</span>: {{ $t('specifications.desk.dimensions_data') }}<br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.weight') }}</span>: {{ $t('specifications.desk.weight_data') }}<br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.monitor') }}</span>: {{ $t('specifications.desk.monitor_data') }}<br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.supply') }}</span>: {{ $t('specifications.desk.supply_data') }} <br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.network') }}</span>: {{ $t('specifications.desk.network_data') }}<br/>
                        </p>
                        <hr />
                    </div>
                    <div class="col-12 col-lg-10 mx-auto">
                        <h5 class="py-2"> {{ $t('specifications.totem.claim') }}</h5>
                        <p class="px-1">
                            <span class="text-highlight">{{ $t('specifications.detailVoices.materials') }}</span>: {{ $t('specifications.totem.materials_data') }}<br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.dimensions') }}</span>: {{ $t('specifications.totem.dimensions_data') }}<br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.weight') }}</span>: {{ $t('specifications.totem.weight_data') }}<br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.monitor') }}</span>: {{ $t('specifications.totem.monitor_data') }}<br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.supply') }}</span>: {{ $t('specifications.totem.supply_data') }} <br/>
                            <span class="text-highlight">{{ $t('specifications.detailVoices.network') }}</span>: {{ $t('specifications.totem.network_data') }}<br/>
                        </p>
                        <hr />
                    </div>
                    <div class="col-12 col-lg-10 mx-auto">
                        <h5 class="py-2">{{ $t('specifications.storeManager.claim') }}</h5>
                        <p class="px-1">
                            {{ $t('specifications.storeManager.description') }}
                        </p>
                        <!-- <hr /> -->
                    </div>
                    <div class="col-12 col-lg-10 mx-auto text-center">
                        <a :href="brochureLink[$i18n.locale]" :download="brochureName[$i18n.locale]" class="btn btn-light">
                            {{ $t('specifications.downloadCatalogue') }} &nbsp; <i class="fa-solid fa-file-arrow-down"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TechnicalData',
    data() {
        return {
            brochureLink: {
                it: "/assets/docs/adaptaeasy_brochure_it.pdf",
                en: "/assets/docs/adaptaeasy_brochure_en.pdf",
            },
            brochureName: {
                it: "adaptaeasy_brochure_it.pdf",
                en: "adaptaeasy_brochure_en.pdf",
            },
        };
    },
};
</script>
