import { createRouter, createWebHistory } from 'vue-router';
import Landing from '../views/Landing.vue';

// import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    // redirect: {
    //   name: 'landing',
    // },
    component: Landing,
  },
  // {
  //   path: '/landing',
  //   name: 'landing',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "landing" */ '../views/Landing.vue'),
  // },
  {
    path: '/cookie-policy',
    name: 'cookiepolicy',
    component: () => import(/* webpackChunkName: "cookiepolicy" */ '../views/CookiePolicy.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacypolicy',
    component: () => import(/* webpackChunkName: "privacypolicy" */ '../views/PrivacyPolicy.vue'),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: {
      name: 'home',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
