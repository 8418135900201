<template>
    <section class="fullpage">
        <div class="row h-100">
            <div class="col-12 col-lg-8 mx-auto align-self-center">
                <h1 class="text-center">{{ $t('team.claim') }}</h1>
            </div>
            <div class="col-12 col-lg-12 col-xl-10 xxl-9 mx-auto align-self-end">
                <div class="row">
                    <div class="col-8 col-lg-3 mx-auto px-0 my-4">
                        <div class="row" data-aos="zoom-in" data-aos-duration="1500">
                            <div class="col-12 px-0">
                                <img src="/assets/imgs/compressed/ae-team-2.webp" alt="ae-marketing-3" class="img-fluid"/>
                            </div>
                            <div class="col-12" style="border: 1px solid white">
                                <h3 class="text-center py-4">Andrea Secl&igrave;</h3>
                                <p class="text-center">
                                    <b>{{ $t('team.product_manager') }}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 col-lg-3 mx-auto px-0 my-4">
                        <div class="row" data-aos="zoom-in" data-aos-duration="1500">
                            <div class="col-12 px-0">
                                <img src="/assets/imgs/compressed/ae-team-1.webp" alt="ae-marketing-3" class="img-fluid"/>
                            </div>
                            <div class="col-12" style="border: 1px solid white">
                                <h3 class="text-center py-4">Luigi Secl&igrave;</h3>
                                <p class="text-center">
                                    <b>{{ $t('team.ceo') }}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 col-lg-3 mx-auto px-0 my-4">
                        <div class="row" data-aos="zoom-in" data-aos-duration="1500">
                            <div class="col-12 px-0">
                                <img  src="/assets/imgs/compressed/ae-team-3.webp" alt="ae-marketing-3" class="img-fluid"/>
                            </div>
                            <div class="col-12" style="border: 1px solid white">
                                <h3 class="text-center py-4">Marco Secl&igrave;</h3>
                                <p class="text-center">
                                    <b> {{ $t('team.business_developer') }}</b>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
