<template>
    <div class="dropdown mx-3 text-center">
        <button class="nav-link btn btn-light w-100" @click="dropdownToggle()" v-if="currentLang">
            <img :src="currentLang.icon" :alt="currentLang.shortName" height="24" class="img-fluid" />
            <span class="d-inline d-lg-none"> &nbsp; {{ currentLang.name }}</span>
        </button>
        <div class="dropdown-menu" :class="{ 'show': dropdownActive === true }" >
            <button class="dropdown-item" v-for="lang in langs" :key="lang.key" @click="selectLocale(lang.key)">
                {{ lang.name }} <img :src="lang.icon" height="24" :alt="lang.shortName" class="img-fluid" />
            </button>
            <!-- <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'LanguageSelector',
    data() {
        return {
            dropdownActive: false,
            langs: [
                {
                    id: 1,
                    key: 'it',
                    name: 'Italiano',
                    shortName: 'IT',
                    icon: require('@/assets/images/flags/italy.png'),
                },
                {
                    id: 2,
                    key: 'en',
                    name: 'English',
                    shortName: 'EN',
                    icon: require('@/assets/images/flags/united-kingdom.png'),
                },
            ],
            currentLang: undefined,
        };
    },
    methods: {
        dropdownToggle() {
            this.dropdownActive = !this.dropdownActive;
        },
        selectLocale(key) {
            const selected = this.langs.find((l) => l.key === key);
            this.currentLang = selected;
            this.dropdownActive = false;
            this.$i18n.locale = key;
        },
    },
    mounted() {
        const selected = this.langs.find((l) => l.key === this.$i18n.locale);
        this.currentLang = selected;
    },
};
</script>
