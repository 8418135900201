<template>
    <section class="fullpage">
        <div class="row h-100">
            <div class="col-sm-12 col-lg-7 offset-lg-0 col-xxl-5 offset-xxl-1 align-self-center" data-aos="flip-right" data-aos-duration="1500">
                <h1>{{ $t('design.customize.claim') }}</h1>
                <p v-html="$t('design.customize.description')">
                </p>
                <h1>{{ $t('design.easyInstall.claim') }}</h1>
                <p>
                    {{ $t('design.easyInstall.description') }}
                </p>
            </div>
            <div class="col-sm-12 col-lg-6 offset-lg-0 col-xxl-5 align-self-center" data-aos="flip-left" data-aos-duration="1500">
                <div class="row">
                    <div class="col-12">
                        <img src="/assets/imgs/compressed/ae-product-7.webp" alt="ae-marketing-3" class="img-fluid"/>
                    </div>
                    <div class="col-12 my-4">
                        <div class="row px-lg-5">
                            <div class="col-12 py-3">
                                <h5>{{ $t('design.customizations.claim') }}*:</h5>
                            </div>
                            <div class="col-8"><p> {{ $t('design.customizations.colors.coal') }} / {{ $t('design.customizations.colors.gray') }}</p></div>
                            <div class="col-4 text-right">
                                <span>&nbsp;</span>
                                <span class="circle-color circle-color-coal circle-color-primary my-1"> &nbsp;</span>
                                <span class="circle-color circle-color-light-gray circle-color-secondary my-1"> &nbsp;</span>
                            </div>
                            <hr/>
                            <div class="col-8"><p>{{ $t('design.customizations.colors.white') }} / {{ $t('design.customizations.colors.cashmere') }}</p></div>
                            <div class="col-4 text-right">
                                <span>&nbsp;</span>
                                <span class="circle-color circle-color-white circle-color-primary my-1"> &nbsp;</span>
                                <span class="circle-color circle-color-cashmere circle-color-secondary my-1"> &nbsp;</span>
                            </div>
                            <hr/>
                            <div class="col-8"><p>{{ $t('design.customizations.colors.cashmere') }} / {{ $t('design.customizations.colors.coal') }}</p></div>
                            <div class="col-4">
                                <span>&nbsp;</span>
                                <span class="circle-color circle-color-cashmere circle-color-primary my-1"> &nbsp;</span>
                                <span class="circle-color circle-color-coal circle-color-secondary my-1"> &nbsp;</span>
                            </div>
                            <hr/>
                            <div class="col-8"><p>{{ $t('design.customizations.colors.gray') }} / {{ $t('design.customizations.colors.blue') }}</p></div>
                            <div class="col-4">
                                <span>&nbsp;</span>
                                <span class="circle-color circle-color-light-gray circle-color-primary my-1"> &nbsp;</span>
                                <span class="circle-color circle-color-dark-blue circle-color-secondary my-1"> &nbsp;</span>
                            </div>
                            <hr/>
                            <div class="col-8"><p>{{ $t('design.customizations.colors.black') }} / {{ $t('design.customizations.colors.gray') }}</p></div>
                            <div class="col-4">
                                <span>&nbsp;</span>
                                <span class="circle-color circle-color-black circle-color-primary my-1"> &nbsp;</span>
                                <span class="circle-color circle-color-light-gray circle-color-secondary my-1"> &nbsp;</span>
                            </div>
                            <div class="col-12 my-4 py-3">
                                <p class="text-small">
                                    <b>*{{ $t('design.customizations.note') }}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Customize',
    };
</script>

<style scoped>
.circle-color {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: relative;
    /* min-width: 2rem; */
    /* min-width: 2rem; */
    /* height: 1rem; */
    /* min-height: 4rem !important; */
    border-radius: 50%;
    border: 1px solid white;
}

.circle-color-coal {
    background-color: #535353;
}

.circle-color-light-gray {
    background-color: #dadad8;
}

.circle-color-cashmere {
    background-color: #e5dcca;
}

.circle-color-white {
    background-color: white;
}
.circle-color-dark-blue {
    background-color: #3c4171;
}

.circle-color-black {
    background-color: #282828;
}

.circle-color-primary {
    left: 0.4rem;
}
.circle-color-secondary {
    left: 0rem;
    z-index: -1;
}
</style>
