<template>
    <div>
        <Navbar>
            <!-- <button class="nav-link btn btn-light" @click="scrollToSection(0)">Home</button> -->
            <button class="nav-link btn btn-light" @click="scrollToSection(1)">{{ $t('menu.idea') }}</button>
            <button class="nav-link btn btn-light" @click="scrollToSection(2)">{{ $t('menu.design') }}</button>
            <!-- <button class="nav-link btn btn-light" @click="scrollToSection(3)">Vantaggi</button> -->
            <button class="nav-link btn btn-light" @click="scrollToSection(3)">{{ $t('menu.details') }}</button>
            <button class="nav-link btn btn-light" @click="scrollToSection(4)">{{ $t('menu.team') }}</button>
            <button class="nav-link btn btn-light" @click="scrollToSection(5)"> {{ $t('menu.contacts') }}</button>
        </Navbar>
        <div class="container-fluid bg-black">
            <section class="fullpage">
                <div class="row" style="min-height:80vh">
                    <div class="col-12 col-lg-6 align-self-center p-0 m-0 text-center">
                        <video autoplay loop muted id="video-b" class="img-fluid">
                            <source src="/assets/video/ae_short_frame.mp4" type="video/mp4">
                            Video non supportato
                        </video>
                    </div>
                    <div class="col-12 col-lg-4 align-self-center">
                        <h1>Adapta Easy<br/> {{ $t('landingBanner.claim') }}</h1>
                        <p>
                            {{ $t('landingBanner.brief') }}
                        </p>
                        <div class="text-right">
                            <button class="btn btn-light" @click="openPromoModal()">
                                {{ $t('landingBanner.watchVideo') }} &nbsp; <i class="fa-solid fa-play"></i>
                            </button>
                            &nbsp;
                            <button class="btn btn-light" @click="scrollToSection(3)">
                                {{ $t('landingBanner.discoverMore') }} &nbsp; <i class="fa-solid fa-arrow-right"></i>
                            </button>

                        </div>
                    </div>
                    <div class="next-section-btn-container">
                        <button 
                            class="btn next-section-btn" 
                            @click="scrollToSection(1)"
                            aria-label="discover-more"
                        ><i class="fa-solid fa-arrow-down"></i></button>
                    </div>
                </div>
            </section>
            <HeroIdea />
            <Customize />
            <!-- <AdvantagesStoreVue></AdvantagesStoreVue> -->
            <!-- <Advantages /> -->
            <TechnicalDataVue></TechnicalDataVue>
            <Team></Team>
             <ContactForm />
             <FooterVue />
             <div class="modal fade p-0" tabindex="-1" id="promomodal">
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content p-0 bg-black">
                        <div class="modal-header" style="background-color:transparent; border: none;">
                            <!-- <h5 class="modal-title">Modal title</h5> -->
                            <button 
                                type="button" style="color:white; background-color:transparent; border:none; width:100%; text-align:right;"
                                @click="closePromoModal()" aria-label="Close"
                            >
                                <i class="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div class="modal-body p-0 m-0">
                            <!-- <p>Modal body text goes here.</p> -->
                            <video id="promovideo" controls preload="none" class="img-fluid" @ended="closePromoModal()">
                                <source type="video/mp4" src="/assets/video/ae_promo_video.mp4"/>
                                <source type="video/webm" src="/assets/video/ae_promo_video.webm"/>
                            </video>
                        </div>
                        <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <vue-cookie-accept-decline
                :debug="true"
                :disableDecline="false"
                :showPostponeButton="false"
                @clicked-accept="cookieClickedAccept"
                @clicked-decline="cookieClickedDecline"
                @clicked-postpone="cookieClickedPostpone"
                @removed-cookie="cookieRemovedCookie"
                @status="cookieStatus"
                elementId="cookiePanel"
                position="bottom"
                ref="cookiePanel"
                transitionName="slideFromBottom"
                type="bar"
                >
                    
                    <template #postponeContent>&times;</template>

                    <template #message>
                        <h3>Cookie Policy</h3>
                        We use cookies to ensure you get the best experience on our website.
                        <a href="https://cookiesandyou.com/" target="_blank">Learn More...</a>
                    </template>
                    <template #acceptContent>
                        Accetto
                    </template>

                    <template #declineContent>
                        Rifiuto
                    </template>

                </vue-cookie-accept-decline> -->
    </div>
</template>
<script>
    import Navbar from '@/components/common/Navbar.vue';
    import HeroIdea from '@/components/LandingPage/HeroIdea.vue';
    // import Advantages from '@/components/LandingPage/Advantages.vue';
    // import AdvantagesStoreVue from '@/components/LandingPage/AdvantagesStore.vue';
    // import HeroHowItWorks from '@/components/LandingPage/HeroHowItWorks.vue';
    import ContactForm from '@/components/LandingPage/ContactForm.vue';
    // import MarketingBanner from '@/components/common/MarketingBanner.vue';
    // import Advantages from '@/components/LandingPage/Advantages.vue';
    import TechnicalDataVue from '@/components/LandingPage/TechnicalData.vue';
    import Team from '@/components/LandingPage/Team.vue';
    import Customize from '@/components/LandingPage/Customize.vue';
    import FooterVue from '@/components/common/Footer.vue';

    export default {
        name: 'LandingPage',
        components: {
            Navbar,
            HeroIdea,
            // Advantages,
            // AdvantagesStoreVue,
            ContactForm,
            TechnicalDataVue,
            Team,
            Customize,
            FooterVue,
        },
        metaInfo() {
            return {
                title: 'AdaptaEasy',
                description: "Con il sistema brevettato Adapta Easy aiutiamo l’ottico optometrista a superare"
                              + "gli ostacoli relativi a tutte quelle lenti che necessitano di un adattamento"
                              + "all’uso, lenti progressive, lenti prismatiche e lenti con alte entità di cilindro."
                              + "L’obiettivo è quello di garantire e gestire l’adattamento in modo più dolce e"
                              + "veloce.",
                htmlAttrs: {
                    lang: 'it',
                },
            };
        },
        data() {
            return {
                inMove: false,
                inMoveDelay: 400,
                activeSection: 0,
                offsets: [],
                touchStartY: 0,
                isPlaying: true,
            };
        },
        methods: {
            cookieClickedAccept() { },
            cookieClickedDecline() { },
            cookieClickedPostpone() { },
            cookieRemovedCookie() { },
            cookieStatus() { },
            openPromoModal() {
                const modal = document.getElementById('promomodal');
                const vid = document.getElementById('promovideo');
                modal.classList.add('show');
                modal.style.display = 'block';
                vid.play();
            },
            closePromoModal() {
                const modal = document.getElementById('promomodal');
                const vid = document.getElementById('promovideo');
                modal.classList.remove('show');
                modal.style.display = 'none';
                if (!vid.paused) {
                    vid.pause();
                }
            },
            /**
             * Calcaulates the absolute offsets of each section on the page and pushs it into the offsets array
             */
            calculateSectionOffsets() {
                const sections = document.getElementsByTagName('section');
                const length = sections.length;
                
                for (let i = 0; i < length; i += 1) {
                    const sectionOffset = sections[i].offsetTop;
                    this.offsets.push(sectionOffset);
                }
            },
            /**
             * Handle the 'mousewheel' event for other browsers
             */
            handleMouseWheel: function (e) {
                // console.log(window.innerWidth);
                const sections = document.getElementsByTagName('section');   
                console.log(this.activeSection, "   ", sections.length);   
                if (window.innerWidth < 992) return true;
                if (this.activeSection + 1 >= sections.length) return true;          
                if (e.wheelDelta < 30 && !this.inMove) {
                    this.moveUp();
                } else if (e.wheelDelta > 30 && !this.inMove) {
                    this.moveDown();
                }
                    
                e.preventDefault();
                return false;
            },
            /**
             * Handle the 'DOMMouseScroll' event for Firefox
             */
            handleMouseWheelDOM: function (e) {       
                const sections = document.getElementsByTagName('section');  
                if (this.activeSection >= sections.length) return true;  
                if (window.innerWidth < 992) return true;   
                if (e.detail > 0 && !this.inMove) {
                    this.moveUp();
                } else if (e.detail < 0 && !this.inMove) {
                    this.moveDown();
                }
                return false;
            },
            /**
             * Move to the previous section or the last section if you're on the first section
             */
            moveDown() {
                // if (this.activeSection < 0) this.activeSection = this.offsets.length - 1;

                if ((this.activeSection - 1) >= 0) {
                    this.inMove = true;
                    this.activeSection -= 1;
                    this.scrollToSection(this.activeSection, true);
                }
            },
            /**
             * Move to the next section or the first section if you're on the last section
             */
            moveUp() {                    
                // if (this.activeSection > this.offsets.length - 1) this.activeSection = 0;
                if ((this.activeSection + 1) <= this.offsets.length - 1) {
                    this.inMove = true;
                    this.activeSection += 1;
                    this.scrollToSection(this.activeSection, true);
                }
            },
            /**
             * Scrolls to the passed section id if the section exists and the delay is over
             */
            scrollToSection(id, force = false) {
                if (this.inMove && !force) return false;

                this.activeSection = id;
                this.inMove = true;
                
                // get section and scroll into view if it exists
                const section = document.getElementsByTagName('section')[id];
                if (section) {
                    document.getElementsByTagName('section')[id].scrollIntoView({ behavior: 'smooth' });
                }
                
                setTimeout(() => {
                    this.inMove = false;
                }, this.inMoveDelay);
                if (this.activeSection === 0) {
                    document.getElementById('video-b').play();
                }
                return true;
            },
            /**
             * Handles the 'touchstart' event on mobile devices
             */
            touchStart(e) {
                if (window.innerWidth < 992) return true;
                e.preventDefault();
                
                this.touchStartY = e.touches[0].clientY;
                return false;
            },
            /**
             * Handles the 'touchmove' event on mobile devices
             */
            touchMove(e) {
                if (this.inMove) return false;
                if (window.innerWidth < 992) return true;
                e.preventDefault();
                const currentY = e.touches[0].clientY;
                
                if (this.touchStartY < currentY) {
                    this.moveDown();
                } else {
                    this.moveUp();
                }
                
                this.touchStartY = 0;
                return false;
            },
        },
        /**
         * mounted() hook executes after page load and call the section offset calculation and registers all events
         */
        mounted() {
            this.calculateSectionOffsets();
            
            // window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM);
            // window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false }); // Other browsers
            
            // window.addEventListener('touchstart', this.touchStart, { passive: false }); // mobile devices
            // window.addEventListener('touchmove', this.touchMove, { passive: false }); // mobile devices
            // this.$refs.cookiePanel.init();
        },
        /**
         * destroyed() hook executes on page destroy and removes all registered event listeners
         */
        unmounted() {
            // window.removeEventListener('DOMMouseScroll', this.handleMouseWheelDOM);
            // window.removeEventListener('mousewheel', this.handleMouseWheel, { passive: false });
            
            // window.removeEventListener('touchstart', this.touchStart); // mobile devices
            // window.removeEventListener('touchmove', this.touchMove); // mobile devices
        },
    };
</script>
