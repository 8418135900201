<template>
    <section class="fullpage">
        <div class="row h-100">
            <div class="col-sm-10 col-lg-6 col-xxl-5 offset-xxl-1 align-self-center">
                <div data-aos="flip-right" data-aos-duration="2000">
                    <h1 class="my-4"> {{ $t('idea.concept.claim') }}</h1>
                    <p class="two-col-text">
                        {{ $t('idea.concept.description') }}
                    </p>
                    <h1 class="my-4">{{ $t('idea.howItWorks.claim') }}</h1>
                    <p>
                        {{ $t('idea.howItWorks.description') }}
                    </p>
                </div>
            </div>
            <div class="col-sm-10 col-lg-6 mx-auto align-self-center">
                <div data-aos="fade-left" data-aos-duration="2500">
                    <img src="/assets/imgs/compressed/ae-table-3.webp" alt="adapta-easy-kit" class="img-fluid" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'HeroIdea',
    components: {
    },
    data() {
        return {

        };
    },
};
</script>
