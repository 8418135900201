import { createApp } from 'vue';
import AOS from "aos";
// import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import './assets/scss/_custom.scss';

// importing AOS css style globally
import 'aos/dist/aos.css';

import i18n from './i18n';

const app = createApp({
    extends: App,
    // onMounted() {
    //     AOS.init();
    // },
}).use(i18n).use(i18n);
app.config.performance = !(process.env.NODE_ENV === 'production');
app.use(AOS.init());
// app.component('vue-cookie-accept-decline', VueCookieAcceptDecline);
app.use(createMetaManager()); // add this line
app.use(metaPlugin);
app.use(store);
app.use(router);
app.mount('#app');
