export default {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
    "idea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idea"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])}
  },
  "landingBanner": {
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il tutor al corretto utilizzo delle lenti"])},
    "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con il sistema brevettato Adapta Easy aiutiamo l’ottico optometrista a superare gli ostacoli relativi a tutte quelle lenti che necessitano di un adattamento all’uso, lenti progressive, lenti prismatiche e lenti con alte entità di cilindro. L’obiettivo è quello di garantire e gestire l’adattamento in modo più dolce e veloce."])},
    "watchVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarda il video"])},
    "discoverMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])}
  },
  "idea": {
    "concept": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da dove nasce l'idea?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il modo di «vedere» di una persona è il risultatodi anni di sviluppo, tutti noi formiamo degli schemi motori e sensoriali che diventano automatici per darci efficienza ed efficacia. Nel momento in cui mettiamo una lente davanti agli occhi tutto quello che abbiamo imparato viene messo in forse. Tutto ciò è amplificato quando mettiamo una lente progressiva davantiagli occhi, in quanto intrinsecamente altera diversi aspetti percettivi: Percezione periferica - Saccadi Inseguimenti - Vergenze - Messa a fuoco- Stima delle distanze - Stima delle dimensioni - Programmazione del movimento. In Italia le ultimestime di SOI(Società Oftalmologica Italiana) parlano di 28 milionidi presbiti, circa il 50% della popolazione. Di contro, nonostante risultiamo uno dei Paesi più vecchi al mondo insieme al Giappone, la penetrazione della vendita di Lenti Progressivesi attesta - secondo i dati GFK - al di sotto del 20%. Per qualemotivo 8 italiani (presbiti) su 10 non scelgono la lente progressiva? Perché esistono dei pregiudizi nei confrontidella lente progressiva, dovuti quasi nella totalità dei casi al mancato adattamento. Ilsistema Adapta Easy nasce proprio per eliminare questi pregiudizi e rendere l’adattamento alla portata di tutti."])}
    },
    "howItWorks": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come funziona Adapta Easy?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con Adapta Easy, abbiamo digitalizzato delle procedure standardizzate che in pochi minuti permettono di gestire l’adattamento in modo più dolce e veloce, garantendo l’adattamento al 100%. Un kit contenente tutti gli strumenti necessari allo svolgimento delle procedure, su di un tavolo interattivo, due schermi digitali touch screen, una smart balance board connessa al sistema, e uno smart bracelet con i quali il cliente dovrà interagire per svolgere le procedure da protocollo, garantendo massima innovazione, interattività e una custumer experience unica per il cliente, senza dimenticare che abbiamo risolto il suo ed anche il nostro problema principale: il mancato adattamento all’uso dellalente progressiva."])}
    }
  },
  "design": {
    "customize": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un design unico e personalizzabile"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’elemento Desk \"Bridge\" è composto da n°2 moduli sovrapposti di l’elemento ad L\" superiore a scorrimento sopra l’elemento poliedrico inferiore, tramite una doppia guida posta nel sottotop tra il modulo superiore ed inferiore. Cassetto a scomparsa con binari a scorrimento contenente il kit strumenti per eseguire le procedure di training. Possibile, previo disponibilità e con supplemento a carico del cliente, personalizzare in una serie di colori predefiniti in fase di preordine. <br/> <br/> La struttura dei totem può essere decoratainteramente con elementi grafici accattivanti o personalizzata con la riproduzione del proprio logo in pellicola adesiva stampata e scontornata"])}
    },
    "easyInstall": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di facile installazione in qualsiasi ambiente"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo strumento si presenta con un design idoneo ad ogni tipo di layout di negozio di ottica, installazione semplice e guidata, alimentazione con presa standard 220V, assistenza in negozio in fase di installazione tramite agente e da remoto con assistenza del team Vysio."])}
    },
    "customizations": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizzazioni standard"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su richiesta qualsiasi personalizzazione di materiali e colori."])},
      "colors": {
        "coal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antracite"])},
        "gray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grigio chiaro"])},
        "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bianco"])},
        "cashmere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashmere"])},
        "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blu intenso"])},
        "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nero"])}
      }
    }
  },
  "specifications": {
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifiche tecniche e caratteristiche"])},
    "desk": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desk"])},
      "materials_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Struttura in legno con cassetto a scomparsa contenente il kit training"])},
      "dimensions_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L. 90+30 x P. 50 x H. 68-76 cm"])},
      "weight_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48 kg"])},
      "monitor_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch monitor FullHD reclinabile da 15.6\""])},
      "supply_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["220V AC"])},
      "network_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi"])}
    },
    "totem": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totem"])},
      "materials_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Struttura in plexiglass metacrilato trasparente"])},
      "dimensions_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" L. 72 x H. 200 x P. 7cm - (Base di sostegno 58x50 cm)"])},
      "weight_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["68 kg"])},
      "monitor_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch monitor FullHD da 31.5\""])},
      "supply_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["220V AC"])},
      "network_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi"])}
    },
    "storeManager": {
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adapta Easy Store-Manager"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software in-cloud che offre all’operatore la possibilità di accedere ad alcuni strumenti utili all’assistenza ottico-optometrica dei propri clienti"])}
    },
    "detailVoices": {
      "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiali"])},
      "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensioni"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
      "monitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitor"])},
      "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentazione"])},
      "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connettività"])}
    },
    "downloadCatalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica la brochure"])}
  },
  "team": {
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Team"])},
    "ceo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO di Vysio s.r.l."])},
    "business_developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Developer"])},
    "product_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Manager"])}
  },
  "contactForm": {
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi avere maggiori informazioni sul sistema Adapta Easy?"])},
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi al nostro team!"])},
    "labels": {
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo E-Mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio"])}
    },
    "placeholders": {
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo indirizzo E-Mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo nome"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo cognome"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome della tua azienda"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([",Scrivi un messaggio al nostro team"])}
    },
    "sendClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia la richiesta"])},
    "messages": {
      "success": {
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per averci contattato!"])},
        "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un membro del nostro team ti ricontatterà a breve."])}
      },
      "error": {
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops! Si è verificato un errore"])},
        "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'invio della tua richiesta. Riprova più tardi."])},
        "retryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova"])}
      }
    }
  },
  "formErrors": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obbligatorio"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non valido"])}
  },
  "footer": {
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
    "gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])}
  }
}